<template>
  <div class="client-photos-component">
    <v-col v-if="!isLoading && photosLoaded === false" class="mt-2 pt-0 pb-0 row-align-right">
      <v-btn color="blue" class="mr-0" style="padding-left: 12px !important; padding-right: 12px !important" @click="getPhotos()">
        {{ str['show_last_feedback_photos'] }}
      </v-btn>
    </v-col>
    <div v-if="isLoading" style="display: flex; justify-content: center; padding: 20px 0">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!isLoading && photos && photos.length && photosLoaded === true" class="mt-2">
      <v-carousel v-if="photos.length" height="300" style="margin-bottom: 20px">
        <v-carousel-item
          v-for="(photo, index) in photos"
          :key="index"
          :src="photo.photo"
          contain
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <div class="icon-photo-download row-flex-align">
            <i class="mdi mdi-tray-arrow-down" style="font-size: 1.2em" @click="downloadImage(photo.photo)" />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientPhotos',
  props: {
    client: Object,
  },
  data() {
    return {
      str: window.strings,
      isLoading: false,
      photos: [],
      photosLoaded: null,
    }
  },
  beforeMount: function () {
    this.main()
  },
  methods: {
    main: function () {
      if (this.client.lastPhysicalEvaluationPhotos) {
        this.photos = this.client.lastPhysicalEvaluationPhotos
      }
      this.photosLoaded = this.photos && this.photos.length ? true : false
    },
    getPhotos: function () {
      const self = this
      this.isLoading = true
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            if (response.data && response.data[0]) {
              Api.getPhysicalEvaluationPhotos(
                {
                  client_id: self.client.dbId,
                  id: response.data[0].id,
                },
                function (resp) {
                  self.photosLoaded = true
                  self.isLoading = false
                  if (resp.success && resp.data && resp.data.length) {
                    self.photos = resp.data
                    self.client.setValue('lastPhysicalEvaluationPhotos', self.photos)
                  }
                },
              )
            } else {
              self.isLoading = false
            }
          } else {
            self.isLoading = false
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    downloadImage: function (image) {
      const a = document.createElement('a')
      a.href = image
      a.download = 'image.png'
      a.click()
    },
  },
}
</script>
